<template>
  <common-layout class="commonlayout">
    <div class="top">
      <div class="header">
        <!-- <img alt="logo" class="logo" src="@/assets/img/logo.png" /> -->
        <!-- <span class="title">{{systemName}}</span> -->
        <div>
          <span
            class="title"
            v-for="(item, index) in systemName"
            :key="index"
            >{{ item }}</span
          >
        </div>
      </div>
      <div class="desc"></div>
    </div>
    <div class="login">
      <a-form @submit="onSubmit" :form="form">
        <!-- <a-tabs size="large" :tabBarStyle="{textAlign: 'center'}" style="padding: 0 2px;">
          <a-tab-pane tab="账户密码登录" key="1"> -->
        <a-alert
          type="error"
          :closable="true"
          v-if="error"
          :message="error"
          showIcon
          style="margin-bottom: 24px;"
          :after-close="closeAlert"
        />
        <a-form-item>
          <a-input
            autocomplete="autocomplete"
            size="large"
            placeholder="请输入账户名"
            v-decorator="[
              'name',
              {
                rules: [
                  { required: true, message: '请输入账户名', whitespace: true },
                ],
              },
            ]"
          >
            <a-icon slot="prefix" type="user" />
          </a-input>
        </a-form-item>
        <a-form-item>
          <a-input
            size="large"
            placeholder="请输入密码"
            autocomplete="autocomplete"
            type="password"
            v-decorator="[
              'password',
              {
                rules: [
                  { required: true, message: '请输入密码', whitespace: true },
                ],
              },
            ]"
          >
            <a-icon slot="prefix" type="lock" />
          </a-input>
        </a-form-item>
        <!-- </a-tab-pane>
          <a-tab-pane tab="手机号登录" key="2">
            <a-form-item>
              <a-input size="large" placeholder="mobile number" >
                <a-icon slot="prefix" type="mobile" />
              </a-input>
            </a-form-item>
            <a-form-item>
              <a-row :gutter="8" style="margin: 0 -4px">
                <a-col :span="16">
                  <a-input size="large" placeholder="captcha">
                    <a-icon slot="prefix" type="mail" />
                  </a-input>
                </a-col>
                <a-col :span="8" style="padding-left: 4px">
                  <a-button style="width: 100%" class="captcha-button" size="large">获取验证码</a-button>
                </a-col>
              </a-row>
            </a-form-item>
          </a-tab-pane>
        </a-tabs> -->
        <div class="checkboxgroup">
          <a-checkbox :checked="saveName" @change="changeSaveName">记住用户名</a-checkbox>
          <!-- <a style="float: right">忘记密码</a> -->
        </div>
        <a-form-item>
          <a-button
            :loading="logging"
            style="width: 100%;margin-top: 24px"
            size="large"
            htmlType="submit"
            type="primary"
            >登录</a-button
          >
        </a-form-item>
        <!-- <div>
          其他登录方式
          <a-icon class="icon" type="alipay-circle" />
          <a-icon class="icon" type="taobao-circle" />
          <a-icon class="icon" type="weibo-circle" />
          <router-link style="float: right" to="/dashboard" >注册账户</router-link>
        </div> -->
      </a-form>
    </div>
    <div class="copyright">
      ©2021 力源宝生态大数据有限公司
    </div>
    <canvas id="digitalRain"> </canvas>
  </common-layout>
</template>

<script>
import CommonLayout from "@/layouts/CommonLayout";
// eslint-disable-next-line no-unused-vars
import { login, getTokenKey, getRoutesConfig } from "@/services/user";
import { AUTH_TYPE, setAuthorization } from "@/utils/request";
import { loadRoutes } from "@/utils/routerUtil";
import { mapGetters, mapMutations } from "vuex";
import { debounceTail } from "@/utils/debounce";

export default {
  name: "Login",
  components: { CommonLayout },
  data() {
    return {
      logging: false,
      saveName: false,
      error: "",
      timer: null,
      resizeHandle: null,
      width: 0,
      height: 0,
      form: this.$form.createForm(this),
      router1: [
        {
          router: "root", //匹配 router.map.js 中注册名 registerName = root 的路由
          children: [
            //root 路由的子路由配置
            {
              router: "dashboard", //匹配 router.map.js 中注册名 registerName = dashboard 的路由
              children: ["userboard"],
              // children: ['analysis'],  //dashboard 路由的子路由配置，依次匹配 registerName 为 workplace 和 analysis 的路由
            },
            {
              // router: 'deviceslist',
              router: "devices",
              // authority: {
              //   role: 'admin'
              // },
              children: ["deviceslist", "devicesdetail"],
            },
            // {
            //   router: 'form',                       //匹配 router.map.js 中注册名 registerName = form 的路由
            //   children: [                           //form 路由的子路由配置
            //     'basicForm',                        //匹配 router.map.js 中注册名 registerName = basicForm 的路由
            //     'stepForm',                         //匹配 router.map.js 中注册名 registerName = stepForm 的路由
            //     {
            //       router: 'advanceForm',            //匹配 router.map.js 中注册名 registerName = advanceForm 的路由
            //       path: 'advance'                   //重写 advanceForm 路由的 path 属性
            //     }
            //   ]
            // },
            // {
            //   router: 'basicForm',                  //匹配 router.map.js 中注册名 registerName = basicForm 的路由
            //   name: '验权表单',                     //重写 basicForm 路由的 name 属性
            //   icon: 'file-excel',                   //重写 basicForm 路由的 icon 属性
            //   authority: 'form'                     //重写 basicForm 路由的 authority 属性
            // }
          ],
        },
      ],
      router2: [
        {
          router: "root",
          children: [
            {
              router: "dashboard",
              children: ["adminboard"],
            },
            {
              // router: 'deviceslist',
              router: "devices",
              // authority: 'admin',
              children: ["deviceslist", "devicesdetail"],
            },
            {
              router: "monitor",
            },
            // {
            //   router: "system",
            //   children: ["user", "model"],
            // },
            {
              router: "user",
            },
            {
              router: "model",
            },
          ],
        },
      ],
    };
  },
  computed: {
    systemName() {
      return this.$store.state.setting.systemName;
    },
    ...mapGetters('setting', ['localConfig'])
  },
  mounted() {
    this.digitalRain();
    // 添加 resize 绑定事件
    this.resizeHandle = debounceTail(() => this.resize(), 299, this);
    window.addEventListener("resize", this.resizeHandle);
    if(this.localConfig.saveLoginUser) {
      this.saveName = true;
    } else {
      this.saveName = false;
    }
    if(this.localConfig.loginUserName) {
      this.form.setFieldsValue({
        'name': this.localConfig.loginUserName,
      });
    }
  },
  beforeDestroy() {
    console.log('login beforeDestroy')
    if(this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
    window.removeEventListener("resize", this.resizeHandle);
  },
  methods: {
    ...mapMutations("account", ["setUser", "setPermissions", "setRoles"]),
    ...mapMutations("setting", ["setLocalConfig", "setAppVersion"]),
    changeSaveName() {
      this.saveName = !this.saveName;
      // console.log("changeSaveName:", e,this.localConfig);
      
      const name = this.form.getFieldValue("name");
      this.setLocalConfig({
        ...this.localconfig,
        loginUserName: this.saveName ? name : '',
        saveLoginUser: this.saveName
      })
    },
    onSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err) => {
        if (!err) {
          this.logging = true;
          const name = this.form.getFieldValue("name");
          const password = this.form.getFieldValue("password");
          getTokenKey().then((res) => {
            console.log("getTokenKey", res);
            if (res.data.meta.success) {
              login(
                name,
                password,
                res.data.data.tokenKey,
                res.data.data.userKey
              ).then((res) => {
                // console.log('login', res)
                this.afterLogin(res);
              }).catch(e => {
                this.error = "登录出错，请稍后重试";
                console.error('login fail', e);
              });
            }
          }).catch(e => {
            this.error = `登录出错 ${e.message}`;
            console.error('login fail', e);
          }).finally(()=>{
            this.logging = false;
          });
        }
      });
    },
    saveLovalConfig() {
      const name = this.form.getFieldValue("name");
      this.setLocalConfig({
        ...this.localconfig,
        loginUserName: this.saveName ? name : '',
        saveLoginUser: this.saveName
      })
    },
    afterLogin(res) {
      this.logging = false;
      const loginRes = res.data;
      console.log("afterLogin:", loginRes);
      if (loginRes.meta.success) {
        // eslint-disable-next-line no-unused-vars
        // const {user, permissions, roles} = loginRes.data
        const { user, jwt, roles } = loginRes.data;
        this.setUser(user);
        // this.setPermissions(permissions)
        // setAuthorization({token: jwt, expireAt: new Date(loginRes.data.expireAt)})
        setAuthorization({ token: jwt }, AUTH_TYPE.AUTH1);
        // 获取路由配置
        // getRoutesConfig().then(result => {
        //   const routesConfig = result.data.data
        //   console.log('getRoutesConfig:', result)
        //   loadRoutes(routesConfig)
        //   this.$router.push('/dashboard')
        //   this.$message.success(loginRes.message, 3)
        // })
        this.saveLovalConfig();
        if (roles === "role_admin") {
          this.setRoles({ id: "admin", operation: ["add", "edit", "delete"] });
          loadRoutes(this.router2);
        } else {
          this.setRoles({ id: "user", operation: [] });
          loadRoutes(this.router1);
        }

        this.setAppVersion(process.env.VUE_APP_VERSION);
        this.$router.push("/dashboard");
        // this.$message.success(loginRes.message, 3)
        this.$message.success("登陆成功", 3);
      } else {
        this.error = loginRes.meta.msg;
      }
    },
    closeAlert() {
      this.error = '';
    },
    digitalRain() {
      var drCav = document.getElementById("digitalRain");
      // let text = this.systemName;
      let text = '01010101010101010101010101010101011♪010101010101';

      /*将画布大小设置为窗口文档显示区大小*/
      this.width = window.innerWidth < 1920 ? 1920 : window.innerWidth;
      this.height = window.innerHeight < 1080 ? 1080 : window.innerHeight;
      drCav.width = this.width;
      drCav.height = this.height;

      var fontsize = 12; //数字字体大小
      var columns = Math.ceil(this.width / fontsize); //列数（向上取整）
      var dropLine = [];
      for (var i = 0; i < columns; i++) {
        dropLine[i] = 0;
      }

      /*Run*/
      if (drCav.getContext) {
        var ctx = drCav.getContext("2d");
        this.timer = setInterval(() => draw(this.width, this.height), 53);
      }

      function draw(width, height) {
        ctx.fillStyle = "rgba(0, 0, 0, 0.07)"; //设置透明度，逐渐覆盖之前的数字
        ctx.fillRect(0, 0, width, height); //填充画布

        ctx.fillStyle = "#0074a3";
        ctx.font = fontsize + "px Simsun";
        for (var i = 0; i < columns; i++) {
          // var figure = Math.floor(Math.random() * 2); //生成0~9的伪随机数
          var figure = Math.floor(Math.random() * text.length); //生成0~9的伪随机数
          // ctx.rotate(90*Math.PI/180);
          /*绘制数字(核心部分)*/
          ctx.fillText(text[figure], i * fontsize, dropLine[i] * fontsize);
          if (dropLine[i] * fontsize > height || Math.random() > 0.95) {
            dropLine[i] = 0;
          }
          dropLine[i]++;
        }
      }
    },
    resize(event){
      console.log('resizeLogic', event, this.width, window.innerWidth)
      if(this.height < window.innerHeight || this.width < window.innerWidth) {
        if(this.timer) {
          clearInterval(this.timer);
          this.timer = null;
          this.digitalRain();
        }
      }
    }
  },
};
</script>

<style lang="less" scoped>
.commonlayout {
  min-height: 800px;
  .top {
    text-align: center;
    .header {
      // height: 44px;
      // line-height: 44px;
      a {
        text-decoration: none;
      }
      .logo {
        height: 44px;
        vertical-align: top;
        margin-right: 16px;
      }
      .title {
        font-size: 72px;
        color: #fff;
        font-family: "Myriad Pro", "Helvetica Neue", Arial, Helvetica,
          sans-serif;
        font-weight: 800;
        position: relative;
        top: 2px;
        margin-bottom: 80px;
        user-select: none;

        // text-shadow: 3px 4px 2px rgba(0, 0, 0, 0.47);
        // color: linear-gradient(0deg, #51e0f7 0%, #0863f7 100%);
        background-image: linear-gradient(
          270deg,
          #6669 0%,
          #ffffff 30%,
          #ffffff 100%
        );
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    .desc {
      font-size: 14px;
      color: @text-color-second;
      margin-top: 12px;
      margin-bottom: 40px;
    }
  }
  .login {
    width: 368px;
    margin: 0 auto;
    @media screen and (max-width: 576px) {
      width: 95%;
    }
    @media screen and (max-width: 320px) {
      .captcha-button {
        font-size: 14px;
      }
    }
    .icon {
      font-size: 24px;
      color: @text-color-second;
      margin-left: 16px;
      vertical-align: middle;
      cursor: pointer;
      transition: color 0.3s;

      &:hover {
        color: @primary-color;
      }
    }
    :deep(.ant-alert-message) {
      color: #ffffff;
    }
  }
  .checkboxgroup {
    display: flex;
    flex-direction: row-reverse;
    color: #fff;
    :deep(.ant-checkbox-wrapper) {
      color: #fff;
    }
  }
  .copyright {
    margin: 0 auto;
    text-align: center;
    width: 230px;
    height: 14px;
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #5f7398;
    line-height: 36px;
  }
}
#digitalRain {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  background-color: #001131;
}
</style>
