var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c('div', {
    staticClass: "common-layout"
  }, [_c('div', {
    staticClass: "content"
  }, [_vm._t("default")], 2)]);
};

var staticRenderFns = [];
export { render, staticRenderFns };