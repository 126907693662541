var render = function render(){var _vm=this,_c=_vm._self._c;return _c('common-layout',{staticClass:"commonlayout"},[_c('div',{staticClass:"top"},[_c('div',{staticClass:"header"},[_c('div',_vm._l((_vm.systemName),function(item,index){return _c('span',{key:index,staticClass:"title"},[_vm._v(_vm._s(item))])}),0)]),_c('div',{staticClass:"desc"})]),_c('div',{staticClass:"login"},[_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.onSubmit}},[(_vm.error)?_c('a-alert',{staticStyle:{"margin-bottom":"24px"},attrs:{"type":"error","closable":true,"message":_vm.error,"showIcon":"","after-close":_vm.closeAlert}}):_vm._e(),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'name',
            {
              rules: [
                { required: true, message: '请输入账户名', whitespace: true },
              ],
            },
          ]),expression:"[\n            'name',\n            {\n              rules: [\n                { required: true, message: '请输入账户名', whitespace: true },\n              ],\n            },\n          ]"}],attrs:{"autocomplete":"autocomplete","size":"large","placeholder":"请输入账户名"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"user"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'password',
            {
              rules: [
                { required: true, message: '请输入密码', whitespace: true },
              ],
            },
          ]),expression:"[\n            'password',\n            {\n              rules: [\n                { required: true, message: '请输入密码', whitespace: true },\n              ],\n            },\n          ]"}],attrs:{"size":"large","placeholder":"请输入密码","autocomplete":"autocomplete","type":"password"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1),_c('div',{staticClass:"checkboxgroup"},[_c('a-checkbox',{attrs:{"checked":_vm.saveName},on:{"change":_vm.changeSaveName}},[_vm._v("记住用户名")])],1),_c('a-form-item',[_c('a-button',{staticStyle:{"width":"100%","margin-top":"24px"},attrs:{"loading":_vm.logging,"size":"large","htmlType":"submit","type":"primary"}},[_vm._v("登录")])],1)],1)],1),_c('div',{staticClass:"copyright"},[_vm._v(" ©2021 力源宝生态大数据有限公司 ")]),_c('canvas',{attrs:{"id":"digitalRain"}})])
}
var staticRenderFns = []

export { render, staticRenderFns }