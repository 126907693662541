import _objectSpread from "G:/worker/DeviceCloud/vue-antd-admin/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import PageFooter from '@/layouts/footer/PageFooter';
import { mapState } from 'vuex';
export default {
  name: 'CommonLayout',
  // eslint-disable-next-line vue/no-unused-components
  components: {
    PageFooter: PageFooter
  },
  computed: _objectSpread({}, mapState('setting', ['footerLinks', 'copyright']))
};